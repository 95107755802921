<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 receptions-screen  gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="icon-hea1">
        <template v-slot:left>
          <router-link :to="{ name: backLinkName }">
            <button>
              <icon icon="#cx-hea1-arrow-left" />
            </button>
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "visitor-management",
              "manage-reception-desks",
              "add-reception-desk"
            )
          }}
        </div>
        <template v-slot:right>
          <button @click="initSubmit" v-if="$route.name == 'r_add-reception'">
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <VeeForm
      ref="addReceptionForm"
      :key="formKey"
      @submit="submitForm"
      v-slot="{ errors }"
      class="form inline-input edit-form"
      novalidate
    >
      <!-- Name, Level, Parent fields -->
      <ul class="clebex-item-section">
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.name }"
        >
          <span class="error-message" v-if="errors.name">
            {{ errors.name }}
          </span>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="name">
                {{
                  displayLabelName(
                    "visitor-management",
                    "manage-reception-desks",
                    "name"
                  )
                }}
              </label>
              <Field
                id="name"
                name="name"
                as="input"
                type="hidden"
                rules="required"
                v-model="displayReceptionName"
              />
              <p class="text-label">
                {{ displayReceptionName }}
              </p>
            </div>
            <span class="follow-up-icons">
              <router-link
                :to="{
                  name: 'r_add-reception-translate-name',
                  params: $route.params
                }"
                class="follow-up-icon-item"
              >
                <icon icon="#cx-men1-translations" />
              </router-link>
            </span>
          </div>
          <Field
            name="has_translations"
            as="input"
            type="hidden"
            rules="required"
            v-model="hasTranslations"
          />
        </li>
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.level_id }"
        >
          <router-link
            :to="{
              name: 'r_add-reception-select-level'
            }"
            class="clebex-item-content-wrapper"
          >
            <div
              class="clebex-section-input"
              v-if="receptionLevel && receptionLevel.name"
            >
              <div class="clebex-section-input-label">
                {{
                  displayLabelName(
                    "visitor-management",
                    "manage-reception-desks",
                    "level"
                  )
                }}
              </div>
              <p class="text-label">{{ receptionLevel.name }}</p>
            </div>
            <div class="label" v-else>
              {{
                displayLabelName(
                  "visitor-management",
                  "manage-reception-desks",
                  "level"
                )
              }}
            </div>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
            <Field name="level_id" as="input" type="hidden" v-model="levelId" />
          </router-link>
        </li>
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.parent_id }"
        >
          <router-link
            :to="{
              name: 'r_add-reception-select-parent'
            }"
            class="clebex-item-content-wrapper"
          >
            <div
              class="clebex-section-input"
              v-if="receptionParent && receptionParent.name"
            >
              <div class="clebex-section-input-label">
                {{
                  displayLabelName(
                    "visitor-management",
                    "manage-reception-desks",
                    "parent"
                  )
                }}
              </div>
              <p class="text-label">{{ receptionParent.name }}</p>
            </div>
            <div class="label" v-else>
              {{
                displayLabelName(
                  "visitor-management",
                  "manage-reception-desks",
                  "parent"
                )
              }}
            </div>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
            <Field
              name="parent_id"
              as="input"
              type="hidden"
              v-model="parentId"
            />
          </router-link>
        </li>

        <!-- All Containers -->
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.container }"
        >
          <router-link
            :to="{
              name: 'r_add-reception-select-allowed-containers',
              params: $route.params
            }"
            class="clebex-item-content-wrapper"
          >
            <div class="label">
              {{
                displayLabelName(
                  "visitor-management",
                  "manage-reception-desks",
                  "allowed-containers"
                )
              }}
            </div>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
          </router-link>
        </li>
        <!-- End All Containers -->

        <!-- Containers -->
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.user_container_id }"
        >
          <router-link
            :to="{
              name: 'r_add-reception-select-container'
            }"
            class="clebex-item-content-wrapper"
          >
            <div
              class="clebex-section-input"
              v-if="receptionContainer && receptionContainer.name"
            >
              <div class="clebex-section-input-label">
                {{
                  displayLabelName(
                    "visitor-management",
                    "manage-reception-desks",
                    "user-container"
                  )
                }}
              </div>
              <p class="text-label">{{ receptionContainer.name }}</p>
            </div>
            <div class="label" v-else>
              {{
                displayLabelName(
                  "visitor-management",
                  "manage-reception-desks",
                  "user-container"
                )
              }}
            </div>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
            <Field
              name="user_container_id"
              as="input"
              type="hidden"
              v-model="containerId"
            />
          </router-link>
        </li>
        <!-- End Containers -->
        <!-- Start Language -->
        <li class="clebex-item-section-item full-right-underline">
          <router-link
            :to="{ name: 'r_add-reception-language' }"
            class="clebex-item-content-wrapper"
          >
            <div class="clebex-section-input">
              <label
                :class="[
                  selectedLanguage ? 'clebex-section-input-label' : 'label'
                ]"
                >{{
                  displayLabelName(
                    "visitor-management",
                    "manage-reception-desks",
                    "language"
                  )
                }}</label
              >
              <p class="text-label ellipsis">
                <template v-if="selectedLanguage">
                  {{ displayLanguage(selectedLanguage.id) }}
                </template>
                <template v-else></template>
              </p>
            </div>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                ></icon>
              </span>
            </span>
          </router-link>
        </li>
        <!-- End Language -->
        <!-- Start Template -->
        <li class="clebex-item-section-item full-right-underline">
          <router-link
            :to="{ name: 'r_add-reception-device-template' }"
            class="clebex-item-content-wrapper"
          >
            <div class="clebex-section-input">
              <label
                :class="[
                  selectedDeviceTemplate
                    ? 'clebex-section-input-label'
                    : 'label'
                ]"
                >{{
                  displayLabelName(
                    "visitor-management",
                    "manage-reception-desks",
                    "template"
                  )
                }}</label
              >
              <p class="text-label ellipsis">
                <template v-if="selectedDeviceTemplate">
                  {{ selectedDeviceTemplate.name }}
                </template>
                <template v-else></template>
              </p>
            </div>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                ></icon>
              </span>
            </span>
          </router-link>
        </li>
        <!-- End Template -->
        <!-- Start Screensaver -->
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label
                class="clebex-section-input-label"
                for="screen_saver_minutes"
                >{{
                  displayLabelName(
                    "visitor-management",
                    "manage-reception-desks",
                    "screensaver"
                  )
                }}</label
              >
              <Field
                id="screen_saver_minutes"
                name="screen_saver_minutes"
                as="input"
                type="text"
              />
            </div>
          </div>
        </li>
        <!-- End Screensaver -->
        <!-- Start Video -->
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="video_url">{{
                displayLabelName(
                  "visitor-management",
                  "manage-reception-desks",
                  "video"
                )
              }}</label>
              <Field id="video_url" name="video_url" as="input" type="text" />
            </div>
          </div>
        </li>
        <!-- End Video -->
        <!-- Start Videoconference -->
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label
                class="clebex-section-input-label"
                for="video_conference_url"
                >{{
                  displayLabelName(
                    "visitor-management",
                    "manage-reception-desks",
                    "videoconference"
                  )
                }}</label
              >
              <Field
                id="video_conference_url"
                name="video_conference_url"
                as="input"
                type="text"
              />
            </div>
          </div>
        </li>
        <!-- End Videoconference -->
        <!-- Start Token -->
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="token">{{
                displayLabelName(
                  "visitor-management",
                  "manage-reception-desks",
                  "token"
                )
              }}</label>
              <Field id="token" name="token" as="input" type="text" />
            </div>
          </div>
        </li>
        <!-- End Token -->
      </ul>

      <!-- Self registration, email, phone and times fields  -->
      <ul class="clebex-item-section">
        <li class="clebex-item-section-item full-right-underline">
          <router-link
            :to="{
              name: 'r_add-reception-select-working-hours',
              params: $route.params
            }"
            class="clebex-item-content-wrapper cursor-pointer"
          >
            <div class="clebex-section-input">
              <label
                class="cursor-pointer"
                :class="
                  workingTimeStart && workingTimeEnd
                    ? 'clebex-section-input-label'
                    : 'label'
                "
              >
                {{
                  displayLabelName(
                    "visitor-management",
                    "manage-reception-desks",
                    "start-time"
                  )
                }}
                -
                {{
                  displayLabelName(
                    "visitor-management",
                    "manage-reception-desks",
                    "end-time"
                  )
                }}
              </label>
              <p v-if="workingTimeStart && workingTimeEnd" class="text-label">
                {{ displayTime(workingTimeStart) }} -
                {{ displayTime(workingTimeEnd) }}
              </p>
            </div>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
          </router-link>
          <span v-if="workingTimeStart && workingTimeEnd">
            <Field
              name="working_time_start"
              as="input"
              type="hidden"
              v-model="workingTimeStart"
            />
            <Field
              name="working_time_end"
              as="input"
              type="hidden"
              v-model="workingTimeEnd"
            />
          </span>
        </li>
      </ul>
      <button
        type="submit"
        ref="submitReceptionForm"
        style="display: none"
      ></button>
    </VeeForm>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState, mapGetters } from "vuex";
import { getLang } from "@/services/http-service";
import { DateTime } from "luxon";
import languagesMixin from "@/services/mixins/languages/languages-mixin";

export default {
  name: "Reception",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  data() {
    return {
      submitting: false,
      formKey: 0,
      selfRegistration: false
    };
  },
  mixins: [languagesMixin],
  created() {
    this.setReception(null);
    this.init();
  },
  computed: {
    ...mapState("reception", [
      "receptionLevel",
      "receptionParent",
      "receptionContainer",
      "accessTypeId",
      "containers",
      "receptionsTranslations",
      "allowedContainers",
      "workingTimeStart",
      "workingTimeEnd"
    ]),
    ...mapState("language", ["languages"]),
    ...mapGetters("settings", ["globalTimeFormat"]),
    ...mapState("companyPreferences", ["preferences"]),
    ...mapState("deviceTemplates", ["selectedDeviceTemplate"]),
    translationFields() {
      const { languages, receptionsTranslations } = this;
      if (languages && languages.data && languages.data.length) {
        return languages.data.map((item, idx) => {
          if (receptionsTranslations && receptionsTranslations.length) {
            return {
              ...receptionsTranslations[idx],
              attributes: {
                ...receptionsTranslations[idx].attributes
              }
            };
          } else {
            return {
              locale: item.locale,
              attributes: {
                name: ""
              }
            };
          }
        });
      }
      return null;
    },
    hasTranslations() {
      const { languages, receptionsTranslations } = this;
      if (languages && languages.data && languages.data.length) {
        return languages.data.length === receptionsTranslations.length;
      }
      return false;
    },
    displayReceptionName() {
      const { receptionsTranslations } = this;
      const locale = getLang();
      if (receptionsTranslations && receptionsTranslations.length) {
        const filtered = receptionsTranslations.filter(
          item => item.locale === locale
        );
        if (filtered && filtered.length) {
          return filtered[0].attributes.name;
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    levelId: {
      get() {
        return this.receptionLevel && this.receptionLevel.id;
      }
    },
    containerId: {
      get() {
        return this.receptionContainer && this.receptionContainer.id;
      }
    },
    parentId: {
      get() {
        return this.receptionParent && this.receptionParent.id;
      }
    },
    selfRegistrationBind() {
      return this.selfRegistration;
    }
  },
  methods: {
    ...mapActions("reception", [
      "createReception",
      "setReception",
      "getReceptions",
      "getLevels",
      "getContainers",
      "getContainerAccessTypes",
      "unsetAllowedContainers"
    ]),
    ...mapActions("companyPreferences", ["getCompanyPreferences"]),
    ...mapActions("language", ["setSelectedLanguage"]),
    ...mapActions("deviceTemplates", ["setSelectedDeviceTemplate"]),
    ...mapActions("pagination", ["setPaginationData"]),
    initSubmit() {
      this.$refs.submitReceptionForm.click();
    },
    init() {
      if (this.$route.name === "r_add-reception") {
        this.setSelectedDeviceTemplate(null);
        this.setSelectedLanguage(null);
        const promises = [];
        if (!this.levels) {
          promises.push(this.getLevels());
        }
        if (!this.receptions) {
          promises.push(this.getReceptions());
        }

        promises.push(
          this.getContainerAccessTypes().then(() => {
            this.getContainers();
          })
        );
        this.unsetAllowedContainers();

        return Promise.all(promises).then(() => {
          this.$store.commit("loader/setScreenLoading", true, { root: true });
          this.getCompanyPreferences("10").finally(() => {
            let workingHoursFromPreference = this.preferences.find(
              el => el.preference === "WORKING_HOURS_FROM"
            );
            let workingHoursToPreference = this.preferences.find(
              el => el.preference === "WORKING_HOURS_TO"
            );
            if (
              workingHoursFromPreference &&
              workingHoursFromPreference.preference_values &&
              workingHoursFromPreference.preference_values.length &&
              workingHoursToPreference &&
              workingHoursToPreference.preference_values &&
              workingHoursToPreference.preference_values.length
            ) {
              this.$store.commit(
                "reception/setWorkingTimeStart",
                workingHoursFromPreference.preference_values[0].value,
                {
                  root: true
                }
              );
              this.$store.commit(
                "reception/setWorkingTimeEnd",
                workingHoursToPreference.preference_values[0].value,
                {
                  root: true
                }
              );
            }
            this.$store.commit("loader/setScreenLoading", false, {
              root: true
            });
          });
        });
      }
    },
    submitForm(values) {
      if (values.level_id && values.level_id.length) {
        values.level_id = values.level_id.id;
      }
      if (values.parent_id && values.parent_id.length) {
        values.parent_id = values.parent_id.id;
      }

      if (values.user_container_id && values.user_container_id.length) {
        values.user_container_id = values.user_container_id.id;
      }
      if (
        this.allowedContainers &&
        typeof this.allowedContainers !== undefined
      ) {
        values.allowed_user_container_ids = this.allowedContainers.map(
          el => el.id
        );
      }
      if (this.receptionsTranslations.length) {
        values.translations = this.receptionsTranslations;
      }

      if (this.selectedLanguage) {
        values.language_id = this.selectedLanguage && this.selectedLanguage.id;
      }

      if (this.selectedDeviceTemplate) {
        values.device_template_id =
          this.selectedDeviceTemplate && this.selectedDeviceTemplate.id;
      }

      this.$store.commit("reception/setWorkingTimeStart", null, { root: true });
      this.$store.commit("reception/setWorkingTimeEnd", null, { root: true });

      this.createReception(values);
      this.setPaginationData(null);
      // this.getReceptions();
      this.$router.push({ name: this.backLinkName });
    },
    displayTime(time) {
      if (!time) {
        return;
      }
      // Luxon fix for am/pm... remove when globalDateFormat is Luxon oriented
      let timeFormat = this.globalTimeFormat;
      if (timeFormat.slice(-1) === "A") {
        timeFormat = timeFormat.replace(/.$/, "a");
      }
      return DateTime.fromISO(time).toFormat(timeFormat || "HH:mm");
    }
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  },

  beforeUnmount() {
    this.$store.commit("reception/setReceptionLevel", null, {
      root: true
    });
    this.$store.commit("reception/setReceptionParent", null, {
      root: true
    });
    this.$store.commit("reception/setReceptionContainer", null, {
      root: true
    });
    this.$store.commit("reception/setReceptionTranslations", [], {
      root: true
    });
    this.$store.commit("reception/setWorkingTimeStart", null, {
      root: true
    });
    this.$store.commit("reception/setWorkingTimeEnd", null, {
      root: true
    });
  }
};
</script>
